<template>
  <div id="dashboard-view" :class="{ openSideMap: openSideMap }">
    <div class="content-box">
      <div class="map-list" v-if="openSideMap">
        <button class="btn-cctv" @click="goCctvList">
          <img src="../assets/camera.png" /><span>CCTV 整合</span>
        </button>

        <img
          @click="goStation(stationInfo.key)"
          class="preview-dot"
          :src="require('../assets/' + stationInfo.key + '-dot.png')"
          v-for="stationInfo in stationInfoList"
          :key="stationInfo.key"
          :class="{ active: stationInfo.key === $route.params.key }"
        />
      </div>
      <div class="top-row">
        <div class="left">
          <youtube
            id="youtube"
            :video-id="videoId"
            @ended="ended"
            :mute="true"
            :player-vars="{ autoplay: 1 }"
          >
          </youtube>
        </div>
        <div class="right">
          <div class="card" id="safe-time">
            <div class="header">安全工時</div>
            <div class="content">
              <div class="normal">108年7月15日起</div>
              <div class="highlight huge">
                {{ dashboardInfo.safeWorkingHours }}小時
              </div>
            </div>
          </div>
          <div class="card" id="work-item">
            <div class="header">施作項目</div>
            <div class="content">
              <div class="huge">{{ dashboardInfo.projectDescription }}</div>
            </div>
          </div>
          <div class="card" id="on-site">
            <div class="header">在場人次</div>
            <div class="content">
              <div class="huge">{{ peopleStatus.onSite }}</div>
            </div>
          </div>
          <div class="card" id="danger-info">
            <div class="header">危害告知</div>
            <div class="content">
              <div class="normal">
                {{ dashboardInfo.hazardNotice }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="heat-cal pm">
        <div class="heat-cal">
          <div class="heat-cal-canvas-area web-block">
            <div id="heat">
              <img
                id="parent_bg"
                :src="require('../assets/heat-' + this.color + '-bg.png')"
              />
              <img id="pin" src="../assets/pin.png" />
            </div>
            <div id="value_area">
              <div>{{ alert }}</div>
            </div>
            <div id="clock">
              現在時間 {{ formatDate(nowDate) }} {{ timestamp }}
            </div>
          </div>
        </div>
      </div>
      <table class="table">
        <thead>
          <th>偵測項目</th>
          <th>1-2 點</th>
          <th>2-2 點</th>
        </thead>
        <tbody>
          <tr>
            <td>氧氣O<span class="small">2</span>(%)</td>
            <td
              :class="
                setClass(
                  'o2',
                  spotAOthers?.attributes?.find((e) => e.key === 'o2').value
                )
              "
            >
              {{
                setRound(
                  spotAOthers?.attributes?.find((e) => e.key === 'o2').value
                )
              }}
            </td>
            <td
              :class="
                setClass(
                  'o2',
                  spotBOthers?.attributes?.find((e) => e.key === 'o2').value
                )
              "
            >
              {{
                setRound(
                  spotBOthers?.attributes?.find((e) => e.key === 'o2').value
                )
              }}
            </td>
          </tr>
          <tr>
            <td>可燃性氣體LEL</td>
            <td
              :class="
                setClass(
                  'hcl',
                  spotAOthers?.attributes?.find((e) => e.key === 'hcl').value
                )
              "
            >
              {{
                formatValue(
                  spotAOthers?.attributes?.find((e) => e.key === 'hcl').value
                )
              }}
            </td>
            <td
              :class="
                setClass(
                  'hcl',
                  spotBOthers?.attributes?.find((e) => e.key === 'hcl').value
                )
              "
            >
              {{
                formatValue(
                  spotBOthers?.attributes?.find((e) => e.key === 'hcl').value
                )
              }}
            </td>
          </tr>
          <tr>
            <td>硫化氫H<span class="small">2</span>S(ppm)</td>
            <td
              :class="
                setClass(
                  'h2s',
                  spotAOthers?.attributes?.find((e) => e.key === 'h2s').value
                )
              "
            >
              {{
                formatValue(
                  spotAOthers?.attributes?.find((e) => e.key === 'h2s').value
                )
              }}
            </td>
            <td
              :class="
                setClass(
                  'h2s',
                  spotBOthers?.attributes?.find((e) => e.key === 'h2s').value
                )
              "
            >
              {{
                formatValue(
                  spotBOthers?.attributes?.find((e) => e.key === 'h2s').value
                )
              }}
            </td>
          </tr>
          <tr>
            <td>一氧化碳CO(ppm)</td>
            <td
              :class="
                setClass(
                  'co',
                  spotAOthers?.attributes?.find((e) => e.key === 'co').value
                )
              "
            >
              {{
                formatValue(
                  spotAOthers?.attributes?.find((e) => e.key === 'co').value
                )
              }}
            </td>
            <td
              :class="
                setClass(
                  'co',
                  spotBOthers?.attributes?.find((e) => e.key === 'co').value
                )
              "
            >
              {{
                formatValue(
                  spotBOthers?.attributes?.find((e) => e.key === 'co').value
                )
              }}
            </td>
          </tr>
          <tr>
            <td>
              <span class="pm"
                >PM<span class="small">2.5</span>(μg/m<span class="small top"
                  >3</span
                ><span class="right-brackets">)</span></span
              >
            </td>
            <td
              :class="
                setClass(
                  'PM2.5',
                  spotAPM?.attributes?.find((e) => e.key === 'PM2.5').value
                )
              "
            >
              {{ spotAPM?.attributes?.find((e) => e.key === 'PM2.5').value }}
            </td>
            <td
              :class="
                setClass(
                  'PM2.5',
                  spotBPM?.attributes?.find((e) => e.key === 'PM2.5').value
                )
              "
            >
              {{ spotBPM?.attributes?.find((e) => e.key === 'PM2.5').value }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="logo-group">
        <img src="~@/assets/futsu-logo.png" id="futsu-logo" />
        <img src="~@/assets/lin-logo.png" id="lin-logo" />
        <img
          src="~@/assets/cec-logo.png"
          id="cec-logo"
          @click="openSideMap = !openSideMap"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getPeopleCount, getAir02 } from '@/api/index.js'
import { getIdFromURL } from 'vue-youtube-embed'
import { mapState } from 'vuex'
export default {
  name: 'Dashboard',
  data() {
    return {
      openSideMap: false,
      alert: '',
      color: '',
      heatTemp: 40,
      tempList: [26.7, 32.2, 40.6, 54.4],
      degreeList: [-90, -60, -15, 63],
      degree: 0,
      peopleStatus: {},
      index: 0,
      spotAPM: null,
      spotBPM: null,
      spotAOthers: null,
      spotBOthers: null,
      timestamp: '',
      air4in1: [],
      airPM: [],
      nowDate:
        new Date().getFullYear() +
        '.' +
        (new Date().getMonth() + 1) +
        '.' +
        new Date().getDate(),
      airStandard: {
        o2: 20,
        hcl: 5000,
        h2s: 10000,
        co: 35000,
        'PM2.5': 35,
      },
    }
  },
  watch: {
    dashboardInfo() {
      console.log('dashboard watcher')
      this.getNow()
      this.init()
      this.setAirData()
      this.getPeopleCount()
      this.setRotate()
    },
  },
  destroy() {},
  computed: {
    ...mapState([
      'isLogin',
      'ytUrl',
      'dashboardInfo',
      'stationInfoList',
      'machineInfo',
    ]),
    videoId() {
      return getIdFromURL(this.ytUrl[this.index])
    },
    deviceGroup() {
      if (this.$route.params.key === 'g07') {
        return '1'
      } else if (this.$route.params.key === 'g10') {
        return '2'
      } else if (this.$route.params.key === 'g12') {
        return '3'
      } else {
        return '4'
      }
    },
  },
  created() {
    this.getPeopleCount()
    this.init()
    this.setAirData()
    this.getNow()
    console.log('created nowDate', this.nowDate)
    this.timeInterval = setInterval(() => {
      console.log('timeInterval nowDate', this.nowDate)
      this.getNow()
      this.init()
      this.setAirData()
      this.getPeopleCount()
    }, 60000)
  },
  mounted() {
    this.setRotate()
    this.timeInterval = setInterval(() => {
      this.setRotate()
    }, 60000)
  },
  methods: {
    setRound(value) {
      return Math.round(value * 10) / 10
    },
    setRotate() {
      document.getElementById('pin').style.transform =
        'translate(-50%, 0%) rotate(' + this.degree + 'deg)'
      if (this.degree < 0) {
        document.getElementById('pin').style.left =
          'calc(50% - ' + 60 / Math.abs(180 / this.degree) + 'px)'
        document.getElementById('pin').style.bottom =
          '-' + 16 / Math.abs(90 / this.degree) + 'px'
      } else {
        document.getElementById('pin').style.left =
          'calc(50% + ' + 60 / Math.abs(180 / this.degree) + 'px)'

        document.getElementById('pin').style.bottom =
          '-' + 16 / Math.abs(90 / this.degree) + 'px'
      }
    },
    init() {
      console.log('init dashboardInfo', this.dashboardInfo)
      this.air4in1 = this.machineInfo.filter(
        (e) =>
          e.machine_group === this.$route.params.key.toUpperCase() &&
          e.machine_type === 'air_4in1'
      )
      this.airPM = this.machineInfo.filter(
        (e) =>
          e.machine_group === this.$route.params.key.toUpperCase() &&
          e.machine_type === 'air_PM2.5'
      )
      let degreePerTemp = 0
      let degreeDistance = 0
      let minIndex = 0
      let maxIndex = 0
      console.log('heatTemp', this.heatTemp)
      this.heatTemp = this.dashboardInfo.heatIndex
      if (this.heatTemp > 60) {
        this.degree = 90
        this.color = 'red'
        this.alert = '熱指數' + this.heatTemp
      } else if (this.heatTemp < 26.7) {
        this.degree = -90
        this.color = 'index'
        this.alert = '未達高溫風險'
      } else {
        this.alert = '熱指數' + this.heatTemp
        for (let index = 0; index < this.tempList.length; index++) {
          if (
            this.heatTemp > this.tempList[index] &&
            this.heatTemp <= this.tempList[index + 1]
          ) {
            minIndex = index
            maxIndex = index + 1
            if (index === 1) {
              this.color = 'green'
            } else if (index === 2) {
              this.color = 'yellow'
            } else {
              this.color = 'orange'
            }
          }
        }
        degreePerTemp =
          (this.degreeList[maxIndex] - this.degreeList[minIndex]) /
          (this.tempList[maxIndex] - this.tempList[minIndex])
        degreeDistance =
          (this.heatTemp - this.tempList[minIndex]) * degreePerTemp
        this.degree = this.degreeList[minIndex] + degreeDistance
      }

      console.log('alert', this.alert)
    },
    formatValue(value) {
      let tempValue = value * (1 / 10000)
      return tempValue < 1 ? 0 : tempValue
    },
    setAirData() {
      const aSpotOtherId = this.air4in1[0].machine_url.substring(
        this.air4in1[0].machine_url.indexOf('?id=') + 4
      )
      const bSpotOtherId = this.air4in1[1].machine_url.substring(
        this.air4in1[1].machine_url.indexOf('?id=') + 4
      )
      getAir02(aSpotOtherId)
        .then((res) => {
          this.spotAOthers = res.data
        })
        .catch((error) => {
          console.log(error)
        })
      getAir02(bSpotOtherId)
        .then((res) => {
          this.spotBOthers = res.data
        })
        .catch((error) => {
          console.log(error)
        })
      const aSpotPMId = this.airPM[0].machine_url.substring(
        this.airPM[0].machine_url.indexOf('?id=') + 4
      )
      const bSpotPMId = this.airPM[1].machine_url.substring(
        this.airPM[1].machine_url.indexOf('?id=') + 4
      )
      getAir02(aSpotPMId)
        .then((res) => {
          this.spotAPM = res.data
        })
        .catch((error) => {
          console.log(error)
        })
      getAir02(bSpotPMId)
        .then((res) => {
          this.spotBPM = res.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setClass(type, value) {
      if (type === 'o2') {
        if (value < this.airStandard[type]) {
          return 'negative'
        } else {
          return 'positive'
        }
      } else {
        if (value > this.airStandard[type]) {
          return 'negative'
        } else {
          return 'positive'
        }
      }
    },
    getPeopleCount() {
      getPeopleCount(this.deviceGroup)
        .then((res) => {
          this.peopleStatus = res.data.peopleStatus
          this.peopleStatus.total =
            this.peopleStatus.FRONT_DOOR + this.peopleStatus.BACK_DOOR
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getNow() {
      this.nowDate =
        new Date().getFullYear() +
        '.' +
        (new Date().getMonth() + 1) +
        '.' +
        new Date().getDate()
      const today = new Date()
      const time =
        (today.getHours() < 10 ? '0' : '') +
        today.getHours() +
        '時' +
        (today.getMinutes() < 10 ? '0' : '') +
        today.getMinutes() +
        '分'
      this.timestamp = time
    },
    formatDate: function (obsTime) {
      const date = new Date(obsTime)
      let d = date.getDate()
      let m = date.getMonth() + 1
      let y = date.getFullYear()
      let fm = function (v) {
        return (v < 10 ? '0' : '') + v
      }
      return y - 1911 + '年' + fm(m) + '月' + fm(d) + '日'
    },
    ended() {
      if (this.index === this.ytUrl.length - 1) {
        this.index = 0
      } else {
        this.index++
      }
    },
    goCctvList() {
      this.$router.push({
        name: 'CctvList',
        params: { key: this.$route.params.key },
      })
    },
    goStation(key) {
      if (!this.isLogin) return
      console.log(' goStation key', key)
      this.$router.replace({ name: 'Map', params: { key: key } })
    },
  },
}
</script>

<style></style>
